import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Headline = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Headline;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/headline.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Headline"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-headline--docs"
      />
      <CodeSnippet
        scope={{ React: React, Headline: Headline }}
        code={snippet}
        platform="react"
        gitHubLink="type/headline"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The text to display in the headline.</Text>
          </PropListItem>

          <ClassnamePartial componentName="headline" />

          <EnvironmentPartial />

          <PropListItem name="hideOverflow" types={['boolean']}>
            <Text>
              Determines whether to truncate the text with an ellipsis.
            </Text>
          </PropListItem>

          <PropListItem name="level" types={['string']}>
            <Text>The level (size) of the headline.</Text>
            <List type="unordered">
              <li>
                <code>1</code> (default)
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscallaneous">
          <QaIdPartial componentName="headline" />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
